import loadable from '@loadable/component';
import { FullWidthField } from '@rategravity/marketing-components';
import { FOG_20 } from '@rategravity/own-up-component-library';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Hero } from '../components/find-your-best-mortgage-mktg02/hero';
import { LandingPageLayout } from '../components/layout';
import { FYBM02HeroImage as HeroImage } from '../components/static-images/old/landing-page';
import { breakpoints } from '../modules/breakpoints';
import { colors } from '../modules/colors';

const FindYourBestMortgageTorso = loadable(
  () => import('../components/find-your-best-mortgage-mktg02/torso'),
  {
    resolveComponent: (components) => components.FindYourBestMortgageTorso
  }
);

const CTA = loadable(() => import('../components/find-your-best-mortgage-mktg02/cta'), {
  resolveComponent: (components) => components.CTA
});

const FindYourBestMortgage = () => {
  const [nerdWallet, setNerdWallet] = useState(false);
  const [urlParams, setUrlParams] = useState({});

  useEffect(() => {
    const currURL = new URLSearchParams(window.location.search);
    if (currURL.get('source') === 'NerdWallet') {
      setNerdWallet(true);
      setUrlParams(Object.fromEntries(currURL.entries()));
    }
  }, []);

  return (
    <main style={{ backgroundColor: colors.AQUA }}>
      <LandingPageLayout>
        <Helmet>
          <style>{`
            @media (max-width: ${breakpoints[2]}px) {
              nav {
                background-color: ${FOG_20};
              }
            }
          `}</style>
        </Helmet>
        <Hero {...{ nerdWallet, urlParams, Image: <HeroImage /> }}></Hero>
        <FullWidthField
          backgroundColor={colors.WHITE}
          desktopMaxWidth={1280}
          mobileLateralGutter={0}
        >
          <FindYourBestMortgageTorso />
        </FullWidthField>
        <CTA {...{ nerdWallet, urlParams }} />
      </LandingPageLayout>
    </main>
  );
};

export default FindYourBestMortgage;
